<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-22 17:04:12
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="order-detail">
    <page-top :title="$t('restaurant_submenu.90D6D1@order_det')">
      <i style="font-size:26px;" class="fa fa-list-alt"></i>
    </page-top>
    <el-card>
      <section class="justify-between">
        <b-btn class="table-btn btn-green" @click="goPrint">{{ $t('orderDetail.C0ED69@print') }}</b-btn>
      </section>
      <el-steps
          style="width:65%;margin:50px auto;"
          :active="detailData.order_course"
          :align-center="true"
      >
        <template v-if="detailData.order_course!=5">
          <el-step :title="$t('orderDetail.F854E2@be_processed')"></el-step>
          <el-step :title="$t('orderDetail.87CE30@In_processing')"></el-step>
          <el-step :title="$t('orderDetail.7879F8@have_received')"></el-step>
          <el-step :title="$t('orderDetail.A119AD@has_payment')"></el-step>
        </template>
        <template v-else>
          <el-step :title="$t('orderDetail.35ED62@order_closed')"></el-step>
        </template>
      </el-steps>
      <section class="order-detail">
        <header class="detail-header">
          <div style="display:flex">
            <h3 style="margin-right:50px">
              {{ $t('placeholder.5C894A@order_code') }}&nbsp;:&nbsp;
              <span>{{ detailData.order_no }}</span>
            </h3>
            <div>
              <h3>
                {{ $t('orderDetail.AB7C91@invoice_number') }}&nbsp;:&nbsp;
                <span
                    v-if="modification"
                >{{ detailData.invoice_no }}</span>
                <el-input v-else style="width:200px;" size="small" v-model="invoice_no" clearable></el-input>
                <el-button
                    style="margin-left:20px"
                    type="info"
                    size="small"
                    @click="update(modification)"
                >{{ modification ? $t('public.B6D57F@btn_modify') : $t('public.1A3EBC@save') }}
                </el-button>
              </h3>
            </div>
          </div>
        </header>
        <div style="display:flex;padding:30px">
          <ul class="info">
            <li>
              <h4
                  style="font-weight:bold;color:#333333;"
              >{{ $t('aboutus.2AD773@Restaurant_Details') }}</h4>
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('backend_submenu.61A3ED@user') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_user_name }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('backend_authrity_authrityList.C90CD7@restaurant_tab') }}</span>
              &nbsp;:&nbsp;{{ detailData.receiver_company_name }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('orderDetail.082549@order_time') }}</span>
              &nbsp;:&nbsp;{{ detailData.create_time }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{
                  $t('supplier-setting-DistributionSet.6198C8@delivery_date')
                }}</span>
              &nbsp;:&nbsp;{{ detailData.delivery_date }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.66D050@delivery_time') }}</span>
              &nbsp;:&nbsp;{{ detailData.delivery_time }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('product_delivery.F5EA13@product_delivery') }}</span>
              &nbsp;:&nbsp;{{ detailData.product_delivery_name }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_buy_genner.FE6497@store') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_shop_name }}
            </li>
            <li v-if="detailData.product_delivery==0">
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_supplier_supplierList.E84BAF@address') }}</span>
              &nbsp;:&nbsp;{{ detailData.receiver_address }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.B8F622@Opening_times') }}</span>
              &nbsp;:&nbsp;{{ detailData.business_time }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_contact }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_setting_companyInfo.D20198@telephone') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_shop_phone }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{
                  $t('aboutus.F31309@fee_ways')
                }}</span>&nbsp;:&nbsp;{{ detailData.pay_method }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;" v-if="detailData.pay==1">
                {{ $t('orderDetail.30DB36@Payment_status') }}&nbsp;:&nbsp;{{ detailData.pay_status }}
              </span>
              <span style="font-weight:bold;color:#333333;" v-else>
                {{
                  $t('orderDetail.30DB36@Payment_status')
                }}&nbsp;:&nbsp;{{ `${detailData.pay_status}(${detailData.pay_method})` }}
              </span>
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('shop-goods.299596@Remarks') }}</span>
              <div v-html="detailData.remark? detailData.remark.replace(/\n/g, '<br>'):''"></div>
            </li>
          </ul>
          <ul class="info">
            <h4
                style="font-weight:bold;color:#333333;margin:10px 0"
            >{{ $t('aboutus.3DB928@Supplier_Details') }}</h4>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('backend_authrity_authrityList.85EDE9@supplier_tab') }}</span>
              &nbsp;:&nbsp;{{ detailData.seller_company_name }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_setting_companyInfo.D20198@telephone') }}</span>
              &nbsp;:&nbsp;{{ detailData.seller_company_phone }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>
              &nbsp;:&nbsp;{{ detailData.seller_contact }}
            </li>
          </ul>
          <ul class="info">
            <section v-if="detailData.bank_name1 && detailData.account_name1 && detailData.account_number1">
              <h4
                  style="font-weight:bold;color:#333333;margin:10px 0"
              >{{ $t('aboutus.2F1443@Bank_Info') }}1：</h4>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.24F080@Bank_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.bank_name1 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.1A4DE1@Account_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_name1 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.C232EA@Account_Number') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_number1 }}
              </li>
            </section>
            <section v-if="detailData.bank_name2 && detailData.account_name2 && detailData.account_number2">
              <h4
                  style="font-weight:bold;color:#333333;margin:10px 0"
              >{{ $t('aboutus.2F1443@Bank_Info') }}2：</h4>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.24F080@Bank_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.bank_name2 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.1A4DE1@Account_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_name2 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.C232EA@Account_Number') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_number2 }}
              </li>
            </section>
          </ul>
        </div>
        <div class="goods">
          <el-button v-if="detailData.order_status_no==1 && canAddProductList.length" size="small" type="success"
                     style="margin-bottom: 5px" @click="addNewRow">
            {{ $t('public.FE11E4@add_goods') }}
          </el-button>
          <el-table
              border
              :data="detailData.goods_list"
              class="mt30"
              style="width: 100%"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align': 'center',}"
          >
            <el-table-column
                prop="gid"
                header-align="center"
                width="80"
                :label="$t('restaurant_buy_optionalOrder.283B63@product_id')"
            ></el-table-column>
            <el-table-column
                prop="goods_no"
                header-align="center"
                :label="$t('aboutus.21AFC6@Product_No')"
            ></el-table-column>
            <el-table-column
                prop="goods_name"
                header-align="center"
                :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')">
              <template slot-scope="scope">
                <span v-if="!scope.row.is_new">
                  {{ scope.row.goods_name }}
                </span>
                <span v-else>
                  <el-select v-model="scope.row.gid" @change="selectAddGoods(scope.$index)" filterable>
                    <el-option v-for="item in canAddProductList" :key="item.gid" :value="item.gid"
                               :label="item.name"></el-option>
                  </el-select>
                </span>
              </template>
            </el-table-column>

            <el-table-column
                prop="unit_name"
                header-align="center"
                :label="$t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit')"
            >
              <template scope="scope">
                <div v-if="!scope.row.is_new">
                  {{ scope.row.unit_num }}/{{ scope.row.unit_name }}
                </div>
                <div v-else>
                  {{ scope.row.unit_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="receive_unit_price"
                header-align="center"
                :label="$t('restaurant_supplier_products.C04851@price')"
                width="130"
            >
              <template slot-scope="scope">
                {{ symbol }}
                <el-input
                    @keyup.native="inputPrice(scope.$index)"
                    v-if="detailData.order_status_no==1"
                    v-model="scope.row.receive_unit_price"
                    size="small"
                    :min="0"
                    style="width:100%;max-width:90px;"
                ></el-input>
                <span v-else>{{ scope.row.receive_unit_price }}</span>
              </template>
            </el-table-column>
            <!--      購買數量      -->
            <el-table-column
                prop="buy_num"
                header-align="center"
                :label="$t('orderDetail.CAF2C7@number')"
                width="135"
            >
              <template slot-scope="scope">
                <el-input-number
                    :precision="3"
                    @change="buyNumChange(scope.$index)"
                    v-if="detailData.order_status_no==1"
                    v-model="scope.row.buy_num"
                    size="small"
                    :min="0"
                    style="width:100%;max-width:125px;"
                ></el-input-number>
                <span v-else>{{ scope.row.buy_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="final_goods_price"
                header-align="center"
                :label="$t('aboutus.CBD374@total_price')"
            ></el-table-column>
            <!--      接收數量      -->
            <el-table-column
                prop="receive_num"
                header-align="center"
                :label="$t('orderDetail.3BB7C2@Accept_number')">
              <template slot-scope="scope">
                <el-input-number
                    @change="receiveNumChange(scope.$index)"
                    v-if="detailData.order_status_no >= 4 && detailData.pay<=1"
                    v-model="scope.row.receive_num"
                    size="small"
                    :min="0"
                    style="width:100%;max-width:125px;"
                ></el-input-number>
                <span v-else>{{ scope.row.receive_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
                header-align="center"
                :label="$t('supplier_power_grouping.0E5FF1@operation')"
                v-if="detailData.order_status_no==1"
                fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                      v-if="!scope.row.is_new"
                      type="danger"
                      @click="delGoods(scope.row)"
                      size="small"
                  >{{ $t('public.1FBB5E@btn_delete') }}
                  </el-button>
                  <el-button
                      v-else
                      type="danger"
                      @click="delNewGoods(scope.$index)"
                      size="small"
                  >{{ $t('public.1FBB5E@btn_delete') }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <section class="statistics">
          <ul>
            <!--      產品總金額      -->
            <li>{{ $t('orderDetail.7CA68E@total_price') }} :&nbsp;&nbsp; {{ detailData.receive_goods_price }}</li>
            <!--      運費      -->
            <li v-if="detailData.order_status_no==1">{{ $t('restaurant_supplier_supplierList.2E31F9@fee') }} :&nbsp;&nbsp;$
              <el-input v-model="receive_deliver_fee" size="mini" style="max-width: 70px;"
                        @keyup.native="inputDigital"></el-input>
            </li>
            <li v-else>
              {{ $t('restaurant_supplier_supplierList.2E31F9@fee') }} :&nbsp;&nbsp;{{ detailData.receive_deliver_fee }}
            </li>
            <!--      訂單總金額      -->
            <li>{{ $t('shop_cart.A91ABF@order_price') }} :&nbsp;&nbsp; {{ detailData.final_price }}</li>
          </ul>
        </section>
        <section class="btn-group">
          <div>
            <!-- <b-btn class="table-btn btn-gray" @click="goBack">{{$t('orderDetail.98FF8B@next')}}</b-btn> -->
            <!-- <b-btn class="table-btn btn-yellow">WhatsApp</b-btn> -->
          </div>
          <div v-if="detailData.status_btn">
            <b-btn
                class="table-btn btn-blue"
                v-if="detailData.status_btn.indexOf(1)!=-1"
                @click="updateOrderStatus(2)"
            >{{ $t('orderDetail.EB17E9@Refuse_order') }}
            </b-btn>
            <b-btn
                class="table-btn btn-blue"
                v-if="detailData.status_btn.indexOf(2)!=-1"
                @click="updateOrderStatus(3)"
            >{{ $t('orderDetail.73FE8F@Cancel_order') }}
            </b-btn>
            <b-btn
                class="table-btn btn-blue"
                v-if="detailData.status_btn.indexOf(3)!=-1"
                @click="clickOrderConfirm"
            >{{ $t('shop-index.382FFB@confirm_order') }}
            </b-btn>
            <b-btn
                class="table-btn btn-blue"
                v-if="detailData.order_status_no>=4 && detailData.pay<=1"
                @click="updateOrderStatus(9)"
            >{{ $t('shop-index.1235FF@modify_order') }}
            </b-btn>
            <b-btn
                class="table-btn btn-green"
                v-if="detailData.order_status_no>=4  && detailData.pay<=1"
                @click="payStatus"
            >{{ $t('orderDetail.A119AD@has_payment') }}
            </b-btn>
          </div>
        </section>
      </section>
      <el-dialog
          title="需要更新產品價格嗎？"
          :visible.sync="dialogVisible"
          width="30%">
        <span>我們將幫你把修改過的產品價格更新至您的產品上</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="clickDialogBtn(1)">{{ $t('aboutus.980E0D@yes') }}</el-button>
          <el-button type="danger" @click="clickDialogBtn(0)">{{ $t('aboutus.A03514@no') }}</el-button><br>
          <el-checkbox :true-label="1" :false-label="0" v-model="hiddenNotification">
            {{ $t('public.not_remind_again') }}
          </el-checkbox>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";

export default {
  data() {
    return {
      //產品價格是否自動修改
      upgradePrice: 0,
      //對話窗隱藏
      dialogVisible: false,
      //隱藏提示方框
      hiddenNotification: 0,
      //產品價格被修改時
      isChangePrice: 0,
      canAddProductList: [],
      receive_deliver_fee: "",
      rateData: {},
      //評分視窗顯示
      rateDialogVisible: false,
      note: "",
      centerDialogVisible: false,
      detailData: {},
      order_no: "",
      goods_json: [],
      invoice_no: "",
      modification: "",
      symbol: ""
    };
  },
  filters: {},
  components: {
    pageTop
  },
  watch: {
    $route(to, from) {
      if (this.order_no != to.query.order_no) {
        this.order_no = to.query.order_no;
        this.getData();
      }
    }
  },
  methods: {
    //選擇新產品
    selectAddGoods(index) {
      let goods = this.canAddProductList.find(g => {
        return g.gid == this.detailData.goods_list[index].gid
      })

      if (goods) {
        this.detailData.goods_list[index].receive_unit_price = goods.price
        this.detailData.goods_list[index].goods_no = goods.goods_no
        this.detailData.goods_list[index].unit_name = goods.unit_name
        this.buyNumChange(index)
        this.checkOrderDeliverFee()
      }
    },
    //取得該筆訂單可以新增的產品清單
    getCanAddProductList(oid) {
      this.$http.getCanAddProductList({
        oid: oid
      }).then(res => {
        this.canAddProductList = res.data
      })
    },
    //刪除新產品
    delNewGoods(index) {
      this.detailData.goods_list = this.detailData.goods_list.slice(0, index)
    },
    //新增一行
    addNewRow() {
      this.detailData.goods_list.push({
        is_new: true,
        buy_num: 0.000,
        receive_num: 0.000
      });
    },
    //輸入接收價格
    inputPrice(index) {
      if (!this.isChangePrice) {
        this.isChangePrice = 1
      }

      let price = this.detailData.goods_list[index].receive_unit_price
      if (price != '') {
        price = price
            //清除"数字"和"."以外的字符
            .replace(/[^\.\d]/g, '')
            //验证第一个字符是数字而不是.
            .replace(/^\./g, '')
            //只保留第一个. 清除多余的.
            .replace(/\.{2,}/g, ".")
            //只允许输入一个小数点
            .replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
            //只能输入两个小数
            .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.detailData.goods_list[index].receive_unit_price = price
      } else {
        this.detailData.goods_list[index].receive_unit_price = parseFloat("0").toFixed(2)
      }
      if (this.detailData.order_status_no == 1) {
        this.buyNumChange(index)
      } else {
        this.receiveNumChange(index)
      }
    },
    //取得金額第三位加上,
    currencyFormat(num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    //接收數量值被改變時
    receiveNumChange(index) {
      this.detailData.goods_list[index].final_goods_price = '$' + (this.detailData.goods_list[index].receive_num * this.detailData.goods_list[index].receive_unit_price).toFixed(2)
      this.detailData.receive_goods_price = "0"
      this.detailData.goods_list.forEach(item => {
        this.detailData.receive_goods_price = (parseFloat(this.detailData.receive_goods_price) + parseFloat(item.final_goods_price.replace('$', '')))
      })
      this.detailData.final_price = this.currencyFormat(this.detailData.receive_goods_price + parseFloat(this.detailData.receive_deliver_fee.replace('$', '')) + parseFloat(this.receive_deliver_fee))
      this.detailData.receive_goods_price = this.currencyFormat(this.detailData.receive_goods_price)
      this.checkOrderDeliverFee()
    },
    //購買數量值被改變時
    buyNumChange(index) {
      this.detailData.goods_list[index].final_goods_price = '$' + (this.detailData.goods_list[index].buy_num * this.detailData.goods_list[index].receive_unit_price).toFixed(2)
      this.detailData.goods_list[index].receive_num = this.detailData.goods_list[index].buy_num.toFixed(2)
      this.detailData.receive_goods_price = "0"
      this.detailData.goods_list.forEach(item => {
        this.detailData.receive_goods_price = (parseFloat(this.detailData.receive_goods_price) + parseFloat(item.final_goods_price.replace('$', '')))
      })
      this.detailData.final_price = this.currencyFormat(this.detailData.receive_goods_price + parseFloat(this.detailData.receive_deliver_fee.replace('$', '')) + parseFloat(this.receive_deliver_fee))
      this.detailData.receive_goods_price = this.currencyFormat(this.detailData.receive_goods_price)
      this.checkOrderDeliverFee()
    },
    //只能輸入數字
    inputDigital() {
      // 使用者輸入時
      if (this.receive_deliver_fee != '') {
        this.receive_deliver_fee = this.receive_deliver_fee
            //清除"数字"和"."以外的字符
            .replace(/[^\.\d]/g, '')
            //验证第一个字符是数字而不是.
            .replace(/^\./g, '')
            //只保留第一个. 清除多余的.
            .replace(/\.{2,}/g, ".")
            //只允许输入一个小数点
            .replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
            //只能输入两个小数
            .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      } else {
        this.checkOrderDeliverFee()
      }
      this.detailData.final_price = this.currencyFormat(
          (parseFloat(this.detailData.receive_goods_price.replace('$', '').replace(',', ''))
              + parseFloat(this.receive_deliver_fee.replace(",", ''))))
    },
    //更新訂單評分
    updateSellerOrderScore() {
      this.$http.updateSellerOrderScore({
        order_gid: this.rateData.order_gid,
        score: this.rateData.score,
        comment: this.rateData.comment,
      }).then(res => {
        if (res.errno == 0) {
          this.rateDialogVisible = false
          this.rateData = {}
          this.getData()
        }
      })
    },
    //評分產品
    rateProduct(data) {
      this.rateData = {
        //order goods id
        order_gid: data.order_gid,
        //score
        score: data.seller_score ? data.seller_score : 5,
        //comment
        comment: "",
        //good picture
        picture: data.picture,
      }
      //show dialog
      this.rateDialogVisible = true
    },
    //获取数据
    getData() {
      let order_no = this.order_no;

      let that = this;
      this.$http.getOrderDetail({order_no}).then(res => {

        function getData(data) {
          data.goods_list.forEach(item => {
            that.symbol = item.receive_unit_price.substring(0, 1);
            item.receive_unit_price = item.receive_unit_price.substring(1);
          });
          return data;
        }

        this.detailData = getData(res.data);
        this.modification = res.data.invoice_no ? true : false;
        this.receive_deliver_fee = this.detailData.receive_deliver_fee.substring(this.detailData.receive_deliver_fee.indexOf('$') + 1)
        this.getCanAddProductList(this.detailData.oid)
        this.checkOrderDeliverFee()
        //取得價格修改是否更新設定檔案
        this.upgradePrice = res.data.upgrade_price

        // 取得隱藏對話窗提示
        this.hiddenNotification = parseInt(this.$getlocalStorage("hiddenNotification"))
        // 產品價格是否有被異動過
        this.isChangePrice = 0
      });
    },
    // 更新发票编号
    update(value) {
      this.modification = !this.modification;
      if (this.modification) {
        this.$http.inputInvoice({
          order_no: this.order_no,
          invoice_no: this.invoice_no
        }).then(res => {
          this.invoice_no = "";
          this.getData();
        });
      }
    },
    // 檢查運費
    checkOrderDeliverFee() {
      let goodsPrice = parseFloat(this.detailData.receive_goods_price.replace('$', '').replace(',', ''))
      let full_slicing = parseFloat(this.detailData.full_slicing.replace(',', ''))
      let deliver_fee = parseFloat(this.detailData.deliver_fee.replace(',', ''))
      if (goodsPrice >= full_slicing) {
        this.receive_deliver_fee = '0.00'
      } else {
        this.receive_deliver_fee = this.currencyFormat(parseFloat(deliver_fee)).replace("$", '')
      }
    },
    //按下對話視窗確認時
    clickDialogBtn(v) {
      this.dialogVisible = false
      this.$setlocalStorage("hiddenNotification", this.hiddenNotification.toString())
      this.upgradePrice = v
      this.$http.setUpgradePriceSetting({upgrade_price: this.upgradePrice})
      this.updateOrderStatus(4)
    },
    //確認訂單
    clickOrderConfirm() {
      // 不隱藏提示以及產品價格被修改時
      if (this.hiddenNotification == 0 && this.isChangePrice) {
        this.dialogVisible = true
      } else {
        this.updateOrderStatus(4)
      }
    },
    // 更改订单状态
    updateOrderStatus(e) {
      function getData(data) {
        let newData = [];
        data.goods_list.forEach((v, i) => {
          let params = {
            gid: v.gid,
            order_gid: v.order_gid,
            unit_price: v.receive_unit_price,
            buy_num: v.buy_num,
            receive_num: v.receive_num,
          };
          newData.push(params);
        });
        return newData;
      }

      getData(this.detailData)
      this.$http.updateOrderStat({
        fee: this.receive_deliver_fee.replace(",", ''),
        order_no: this.order_no,
        status_no: e,
        goods_json: JSON.stringify(getData(this.detailData)),
        upgrade_price: this.upgradePrice,
      }).then(res => {
        this.getData()
      })
    },
    // 更改订单支付状态
    payStatus() {
      this.$confirm(
          this.$t("shop-goods.ABFDC5@Confirm_the_change"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.updateOrderPayStat({order_no: this.order_no, pay_status: 2}).then(res => {
          if (res.errno == 0) {
            this.getData();
          }
        });
      });
    },
    goPrint() {
      const {href} = this.$router.resolve({
        name: "print",
        query: {order_no: this.detailData.order_no, type: this.type}
      });
      window.open(href, "_blank");
    },
    //删除
    delGoods(row) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.delOrderGoods({
          order_no: this.order_no,
          order_gid: row.order_gid
        }).then(res => {
          this.getData();
        });
      });
    },
    goBack() {
      this.$router.push("order");
    }
  },
  created() {
    if (!this.$getlocalStorage("hiddenNotification")) {
      this.$setlocalStorage("hiddenNotification", "0")
    }
    this.order_no = this.$route.query.order_no;
    this.getData();
  },
  updated() {
  },
};
</script>
<style lang="scss">

.order_score {
  display: flex;
  flex-direction: column;

  .image, .title, .star {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vh;
  }

  .comment {
    display: flex;
    align-items: center;
    justify-content: center;

    &.ta {
      margin-top: 1vh;
    }

    &.btn {
      margin-top: 1vh;
    }
  }
}

.order-detail {
  display: flex;
  flex-direction: column;

  .detail-header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #e6e6e6;

    div {
      h3 {
        font-size: 16px;
        font-weight: bold;

        > span {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          color: #333333;
        }
      }

      span {
        font-size: 12px;
        font-stretch: normal;
      }
    }

    section {
      span {
        font-size: 14px;
        color: #666666;
        padding-right: 10px;
      }
    }
  }

  .info {
    margin-right: 120px;

    li {
      font-size: 14px;
      padding: 10px 0;
      color: #666;
    }
  }

  .statistics {
    overflow: hidden;
    background-color: #f0f0f0;
    margin: 20px 0;
    padding: 5px 15px;

    ul {
      float: right;
      text-align: right;

      li {
        padding-top: 5px;

        b {
          font-weight: 900;
        }

        input {
          text-align: right;
        }
      }
    }
  }

  .note {
    span {
      font-weight: 900;
    }
  }

  .btn-group {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
